<template>
  <div>
    <a-drawer
      class="modal-add-order"
      title="Thông tin tạo mới đơn hàng"
      width="860px"
      :visible="visible ? visible : false"
      @ok="handleOk"
      @close="handleCancel"
    >
       <FormAddOrder  :visible="visible" :togleVisible="togleVisible"/>
    </a-drawer>
  </div>
</template>

<script>
import FormAddOrder from './FormAddOrder'
export default {
  props: {
    togleVisible: Function,
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    };
  },
  components:{
    FormAddOrder: FormAddOrder
  },
  methods: {
    handleOk() {
      setTimeout(() => {
        this.togleVisible();
      }, 2000);
    },
    handleCancel() {
      this.togleVisible();
    }
  }
};
</script>

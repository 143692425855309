<template>
  <div>
    <a-modal
      class="modal-change-note"
      :title="modalEdit.title === 'adminNote' ?  'Ghi chú' : 'Ship'"
      :visible="modalEdit.visible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="content-body" v-if="modalEdit.title === 'adminNote'">
        <h3>Ghi chú của quản lý:</h3>
        <a-radio-group @change="onChange" v-model="selected">
          <a-radio :style="radioStyle" v-for="item in list" :key="item.value" :value="item.value">
            {{item.text}}
            <a-textarea
              v-if="selected === 4 && item.value === 4"
              v-model="textOther"
              :style="{height: '32px'}"
            />
          </a-radio>
        </a-radio-group>

        <!-- <a-textarea v-model="text" /> -->
      </div>

      <div class="content-body" v-if="modalEdit.title === 'shipPayment'">
        <h3>Thay đổi phí ship:</h3>
        <a-input v-model="text" />
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: ["onchangeEdit", "modalEdit"],
  data() {
    return {
      text: "",
      textOther: "Khác: ",
      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px"
      },
      list: [
        { value: 1, text: "Xác Nhận Không Nghe Máy" },
        { value: 2, text: "Gọi Thuê Bao" },
        { value: 3, text: "Giao Hàng Thất bại" },
        { value: 4, text: "Lý Do Khác" }
      ],
      selected: 0
    };
  },
  watch: {
    modalEdit() {
      this.text = this.modalEdit[this.modalEdit.title];
      this.selected = 0;
      this.textOther = "Khác: ";
    },
    textOther() {
      this.text = this.textOther;
    },
    text() {}
  },
  methods: {
    onChange(e) {
      this.selected = e.target.value;
      if (this.selected === 4) {
        this.text = this.textOther;
      } else {
        this.text = this.list[this.selected - 1].text;
      }
    },
    handleOk() {
      const key = this.modalEdit.title;
      if (this.text !== this.modalEdit[key]) {
        const data = { ...this.modalEdit, [key]: this.text };
        this.onchangeEdit(data, true);
      }
    },
    handleCancel() {
      this.onchangeEdit({ ...this.modalEdit, visible: false }, false);
    }
  }
};
</script>
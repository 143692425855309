<template>
  <div>
    <a-modal
      class="modal-detail-order"
      title="Thông tin đơn hàng"
      width="860px"
      :visible="dataDetail.code ? true : false"
      @ok="handleOk"
      @cancel="handleCancel"
      :footer='null'
    >
    
      <a-row>
        <a-col :span="8">Mã đơn hàng:</a-col>
        <a-col :span="16">{{dataDetail.code}}</a-col>
      </a-row>
      <a-row>
        <a-col :span="8">Ngày tạo:</a-col>
        <a-col :span="16">{{dataDetail.createdDate}}</a-col>
      </a-row>
        <a-row>
            <a-col :span="8">Tên khách hàng:</a-col>
            <a-col :span="16"><strong>{{dataDetail.customerFullName}}</strong></a-col>
        </a-row>
      <a-row>
        <a-col :span="8">Số điện thoại:</a-col>
        <a-col :span="16"><strong>{{dataDetail.customerPhone}}</strong></a-col>
      </a-row>
        <a-row>
            <a-col :span="8">Địa chỉ nhận hàng:</a-col>
            <a-col :span="16">{{dataDetail.customerAddress}}</a-col>
        </a-row>
       <a-row>
        <a-col :span="8">Trạng thái đơn hàng:</a-col>
        <a-col :span="16">{{dataDetail.orderStatus && dataDetail.orderStatus.name}}</a-col>
      </a-row>
       <a-row>
        <a-col :span="8">Ghi chú của khách hàng:</a-col>
        <a-col :span="16">{{dataDetail.customerNote}}</a-col>
      </a-row>
       <a-row>
        <a-col :span="8">Ghi chú của nhân viên:</a-col>  
        <a-col :span="16">{{dataDetail.adminNote}}</a-col>
      </a-row>
      <a-row>
        <a-col :span="8">Tổng tiền:</a-col>
        <a-col :span="16">{{formatNumber(dataDetail.totalPayment)}}</a-col>
      </a-row>
      <a-row>
        <a-col :span="8">Phí ship:</a-col>
        <a-col :span="16">{{formatNumber(dataDetail.shipPayment)}}</a-col>
      </a-row>
      <div v-if="dataDetail.products && dataDetail.products.length > 0" class="list-product">
        <a-row :style="{fontSize: '16px'}">
          <a-col :span="8">Tên sản phẩm</a-col>
          <a-col :span="8">Ảnh</a-col>
          <a-col :span="8">Số lượng</a-col>
        </a-row>
        <div class="item" v-for="(item, i) in dataDetail.products" :key="i">
          <a-row :style="{ marginBottom: '0'}">
            <a-col :span="8">{{item.name}}</a-col>
            <a-col :span="8">
              <a-avatar shape="square" :size="64" :src="item.featureImage" />  
            </a-col>
            <a-col :span="8" :style="{color: 'red'}">{{item.quantity}}</a-col>
          </a-row>
        </div>
      </div>
     <a-button type="primary" @click="loadHistoryOrder" :style="{marginBottom: '10px'}">Tra cứu thông tin trạng thái đơn hàng</a-button>

      <a-row v-if="dataHistory.length > 0">
        <a-col :span="6">Trạng thái</a-col>
        <a-col :span="6">Người thay đổi</a-col>
        <a-col :span="6">Ngày thay đổi</a-col>
        <a-col :span="6">Ghi chú</a-col>
      </a-row>

      <a-row v-for="(i,k) in dataHistory" :key="k">
        <a-col :span="6">{{i.orderStatus.description}}</a-col>
        <a-col :span="6">{{i.updateUser}}</a-col>
        <a-col :span="6">{{moment(i.updatedDate).format("DD/MM/YYYY")}}</a-col>
        <a-col :span="6">{{i.note}}</a-col>
      </a-row> 

    </a-modal>
  </div>
</template>
<script>
import {formatCurrency} from '@/components/FormatString.js'
import moment from "moment";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const orderRepository = RepositoryFactory.get("order");

export default {
  props: {
    setVisibleDetail: Function,
    dataDetail: {
      type: Object,
    }
  },
  data() {
    return {
      confirmLoading: false,
      visible: false,
      dataHistory: []
    };
  },
  methods: {
    handleOk() {
      this.setVisibleDetail();
    },
    handleCancel() {
      this.dataHistory = [];
      this.setVisibleDetail();
    },
    formatNumber: formatCurrency,
    async loadHistoryOrder(){
      try{
        const {data} = await orderRepository.getHistory(this.dataDetail.id); 
        if (!data.meta.success) throw new Error(data.meta.externalMessage); 
        this.dataHistory = data.data; 
      }catch(e){
         this.openNotification("Lịch sử đơn hàng chưa có!");
      }
    },
    moment: moment,
    openNotification(message) {
      this.$notification.open({
        message: message
      });
    },
  }
};
</script>

<style scoped lang="scss">
.modal-detail-order {
  .ant-modal-body {
    .item {
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 5px 10px;
      margin-bottom: 10px;
    }
    .ant-row {
      margin-bottom: 17px;
      display: flex;
      align-items: center;
      color: #111;
    }
  }
}
</style>

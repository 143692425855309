<template>
  <div class="form-add-order">
    <a-form :form="form" @submit="handleSubmit">
      <a-form-item v-bind="formItemLayout" label="Mã khách hàng">
        <a-input
          placeholder="Có thể để trống nếu là khách hàng mới"
          v-decorator="[
          'customerId'
        ]"
          type="number"
        />
      </a-form-item>
      <a-form-item v-bind="formItemLayout" label="Phone">
        <a-input
          maxlength="10"
          v-decorator="[
          'customerPhone',
          {
            rules: [{ required: true, message: 'Số điện thoại không được trống!' }],
          }
        ]"
        />
      </a-form-item>

      <a-form-item v-bind="formItemLayout" label="Đia chỉ nhận hàng">
        <a-input
          v-decorator="[
          'customerAddress',
          {
            rules: [
              {
                required: true,
                message: 'Địa chỉ không được bỏ trống!',
              },
            ],
          },
        ]"
          type="text"
        />
      </a-form-item>
      <a-form-item v-bind="formItemLayout" label="Ghi chú của người dùng">
        <a-textarea v-decorator="[
          'customerNote'
        ]" type="text" />
      </a-form-item>
      <a-form-item v-bind="formItemLayout" label="Ghi chú của quản lí">
        <a-textarea v-decorator="[
          'adminNote'
        ]" type="text" />
      </a-form-item>

      <a-form-item v-bind="formItemLayout" label="Nhập số tiền ship">
        <a-input
          v-decorator="[
          'shipPayment',
          {
            rules: [{ required: true, message: 'Tiền ship không được trống!' }],
          }
        ]"
          type="text"
        />
      </a-form-item>
      <a-form-item v-bind="formItemLayout" label="Chọn sản phẩm">
        <a-select
          showSearch
          :value="value"
          placeholder="Nhập tên sản phẩm vd: Kem dưỡng da..."
          style="width: 100%"
          :defaultActiveFirstOption="false"
          :showArrow="false"
          :filterOption="false"
          @search="handleSearch"
          @change="handleChange"
          :notFoundContent="null"
        >
          <a-select-option v-for="(d, k) in data" :key="k">{{d.name}}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-row class="title-product">
          <a-col :span="6">Tên sản phẩm</a-col>
          <a-col :span="3">Ảnh</a-col>
          <a-col :span="3"></a-col>
          <a-col :span="6">Số lượng</a-col>
          <a-col :span="3">Tiền hàng</a-col>
          <a-col :span="3">Thao tác</a-col>
        </a-row>
      </a-form-item>

      <div class="item-product" v-for="(d, index) in products" :key="d.id">
        <a-row>
          <a-col :span="6">{{d.name}}</a-col>
          <a-col :span="3">
            <div class="img">
              <a
                :href="d.featuredImage ? d.featuredImage.url: d.featureImage"
                title
                target="_blank"
                rel="noopener noreferrer"
              >
                <a-avatar
                  shape="square"
                  :size="64"
                  :src="d.featuredImage ? d.featuredImage.url: d.featureImage"
                />
              </a>
            </div>
          </a-col>
          <a-col :span="3"></a-col>
          <a-col :span="6" :style="{color: 'red'}">
            <a-input-number
              :min="1"
              :max="1000"
              :value="d.quantity"
              @change="e => onChangeQuantity(e, index)"
            />
          </a-col>
          <a-col :span="3">{{d.payment}} vnd</a-col>
          <a-col :span="3" :style="{color: 'red', textAlign: 'center'}">
            <span @click="() => deleteProduct(index)" :style="{cursor: 'pointer'}">Xóa</span>
          </a-col>
        </a-row>
      </div>
      <a-button type="primary" html-type="submit">Tạo mới đơn hàng</a-button>
    </a-form>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const orderRepository = RepositoryFactory.get("order");

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    togleVisible: Function
  },
  data() {
    return {
      data: [],
      dfData: [],
      products: [],
      value: undefined,
      confirmDirty: false,
      autoCompleteResult: [],
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      }
    };
  },
  created() {
    this.getAllProductErp();
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "register" });
  },

  methods: {
    onChangeQuantity(value, key) {
      this.products[key].quantity = value;
      this.products[key].payment = value * this.products[key].price;
    },
    deleteProduct(key) {
      this.products.splice(key, 1);
    },
    async getAllProductErp() {
      try {
        let { data } = await orderRepository.getAllProductErp();
        if (!data.meta.success) throw new Error(data.meta.externalMessage);
        this.data = data.data.content;
        this.dfData = data.data.content;
      } catch (e) {
        this.openNotification("Có lỗi khi hiển thị thông tin!");
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          const _products = this.products.map(item => {
            return {
              productId: item.id,
              quantity: item.quantity,
              promotionId: item.promotionId,
              attributeId: item.attributeId,
              attributeValueId: item.attributeValueId
            };
          });
          const data = { ...values, products: _products };
          // eslint-disable-next-line no-console
          console.log("Received values of form: ", data);
          orderRepository
            .createOrder(data)
            .then(() => {
              this.openNotification("Tạo mới đơn hàng thành công !");
              this.form.resetFields();
              this.products = [];
              this.togleVisible(true);
            })
            .catch(() => {
              this.openNotification("Có lỗi khi thêm đơn hàng!");
            });
        }
      });
    },
    handleSearch(value) {
      const _data = this.dfData.filter(i =>
        i.name.toLowerCase().includes(value.toLowerCase())
      );
      this.data = { ..._data };
    },
    checkDuplicateProduct(value) {
      if (this.products.filter(i => i.id === value).length > 0) return true;
      return false;
    },
    handleChange(value) {
      const _item = this.dfData[value];
      if (this.checkDuplicateProduct(_item.id)) {
        this.openNotification("Sản phẩm được chọn đã có trong danh sách!");
      } else {
        const item = { ..._item, quantity: 1, payment: _item.price };
        this.products.push(item);
        // eslint-disable-next-line no-console
        console.log(this.products);
      }
    },
    openNotification(message) {
      this.$notification.open({
        message: message
      });
    }
  },
  watch: {
    visible() {
      this.products = [];
    }
  }
};
</script>

<style scoped lang="scss">
.form-add-order {
  .title-product {
    font-size: 16px;
    // text-align: center;
  }
  .item-product {
    padding: 5px 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    .ant-row {
      display: flex;
      align-items: center;
    }
  }
}
</style>

<template>
  <a-layout-content class="Dashboard">
    <a-breadcrumb style="margin: 16px 24px">
      <a-breadcrumb-item>Trang chủ</a-breadcrumb-item>
      <a-breadcrumb-item>Dashboard</a-breadcrumb-item>
    </a-breadcrumb>
    <div
      class="Dashboard"
      :style="{minHeight: 'calc(100vh - 120px)', backgroundColor: '#fff', margin: '15px 25px', padding: '15px' }"
    >
      <div class="header">
        <h2>Quản lí đơn hàng</h2>
        <div class="action">
          <a-button type="primary" @click="togleVisible">Thêm mới đơn hàng</a-button>
        </div>
      </div>
      <ModalAddOrder :togleVisible="togleVisible" :visible="visible" />
      <ModalDetail :setVisibleDetail="setVisibleDetail" :dataDetail="dataDetail" />
      <ModalChangeAdminNote :onchangeEdit="onchangeEdit" :modalEdit="modalEdit" />
      <a-table
        :columns="columns"
        :rowKey="record => record.id"
        :dataSource="data"
        :loading="loading"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <div class="aciton" slot="action" slot-scope="text,record">
          <a-button @click="() => setVisibleDetail(record)">Chi tiết</a-button>
        </div>

        <div
          class="admin-note"
          slot="adminNote"
          slot-scope="text,record"
          @click="()=>setDataEdit(record, 'adminNote')"
        >
          <span>{{text ? text : '&nbsp;'}}</span>
        </div>

        <div
          class="admin-note"
          slot="shipPayment"
          slot-scope="text,record"
          @click="()=>setDataEdit(record, 'shipPayment')"
        >
          <span>{{text ? formatCurrency(text) : '0đ'}}</span>
        </div>

        <div class="total-payment" slot="totalPayment" slot-scope="text">{{formatCurrency(text)}}</div>

        <div class="status" slot="orderStatus" slot-scope="text,record">
          <a-select
            :defaultValue="text.name"
            :class="`status${text.step}`"
            style="width: 182px"
            :value="text.name"
          >
            <a-select-option v-for="(d, k) in listStatus" :key="k" :value="d.step">
              <a-popconfirm
                title="Bạn có chắc chắn muốn chuyển trạng thái?"
                @confirm="handleConfirm(record, d.id)"
                @cancel="modalCancel"
                okText="Yes"
                cancelText="No"
              >
                <div>{{d.name}}</div>
              </a-popconfirm>
            </a-select-option>
          </a-select>
        </div>

        <div
          slot="filterDropdown"
          slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="c => searchInput = c"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm)"
            style="width: 188px; margin-bottom: 8px; display: block;"
          />
          <a-button
            type="primary"
            @click="() => handleSearch(selectedKeys, confirm)"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
          >Search</a-button>
          <a-button @click="() => handleReset(clearFilters)" size="small" style="width: 90px">Reset</a-button>
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
        <!-- <template slot="customRender" slot-scope="text">
          <span v-if="searchText">
            <template
              v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{text}}</template>
        </template>-->
      </a-table>
    </div>
  </a-layout-content>
</template>

<script>
import "./_Order.scss";
import ModalAddOrder from "./ModalAddOrder";
import ModalDetail from "./ModalDetail";
import ModalChangeAdminNote from "./ModalChangeAdminNote";
import moment from "moment";
import { formatCurrency, formatPhone } from "@/components/FormatString.js";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const orderRepository = RepositoryFactory.get("order");

export default {
  mounted() {
    this.fetch();
  },
  data() {
    return {
      data: [],
      searchText: "",
      orderStatus: true,
      searchInput: null,
      loading: false,
      visible: false,
      dataDetail: {},
      listStatus: [],
      pagination: { current: 1 },
      columns: [
        {
          title: "Mã đơn",
          dataIndex: "code",
          width: "10%",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon"
          }
        },
        {
          title: "Số điện thoại",
          dataIndex: "customerPhone",
          key: "customerPhone",
          width: "8%",
          customRender: value => formatPhone(value),
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon"
          },
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          }
        },
        {
          title: "Địa chỉ",
          dataIndex: "customerAddress",
          width: "20%"
        },
        {
          title: "Phí ship",
          dataIndex: "shipPayment",
          width: "7%",
          scopedSlots: { customRender: "shipPayment" }
        },
        {
          title: "Quản trị ghi chú",
          dataIndex: "adminNote",
          width: "17%",
          filterMultiple: false,
          filters: [
            {
              text: "Xác Nhận Không Nghe Máy",
              value: "Xác Nhận Không Nghe Máy"
            },
            {
              text: "Gọi Thuê Bao",
              value: "Gọi Thuê Bao"
            },
            {
              text: "Giao Hàng Thất Bại",
              value: "Giao Hàng Thất Bại"
            },
            {
              text: "Lý Do Khác",
              value: "Khác"
            }
          ],
          scopedSlots: { customRender: "adminNote" }
        },
        {
          title: "Tổng tiền",
          dataIndex: "totalPayment",
          width: "10%",
          scopedSlots: { customRender: "totalPayment" }
        },
        {
          title: "Trạng thái đơn hàng",
          dataIndex: "orderStatus",
          width: "13%",
          filters: [
            {
              text: "Chờ xác nhận",
              value: "1"
            },
            {
              text: "Đang giao hàng",
              value: "3"
            },
            {
              text: "Giao hàng thành công",
              value: "5"
            },
            {
              text: "Đã hủy",
              value: "7"
            }
          ],
          scopedSlots: { customRender: "orderStatus" }
        },
        {
          title: "Ngày tạo",
          dataIndex: "createdDate",
          width: "10%",
          customRender: value => moment(value).format("DD/MM/YYYY HH:MM"),
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon"
          },
          sorter: true
        },
        {
          title: "Hành động",
          key: "action",
          scopedSlots: { customRender: "action" },
          width: "5%"
        }
      ],
      modalEdit: {
        visible: false,
        adminNote: "",
        shipPayment: ""
      }
    };
  },
  components: {
    ModalAddOrder,
    ModalDetail,
    ModalChangeAdminNote
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      let search = null;
      if (sorter.field && sorter.order) {
        search = sorter.order === "ascend" ? 0 : 1;
      }
      let statusIds = "";
      if (filters.orderStatus && filters.orderStatus.length) {
        filters.orderStatus.forEach(element => {
          statusIds += element + ",";
        });
      }

      const _params = {
        statusIds: statusIds.length
          ? statusIds.slice(0, statusIds.length - 1)
          : null,
        customerPhone: filters.customerPhone ? filters.customerPhone[0] : null,
        code: filters.code ? filters.code[0] : null,
        adminNote: filters.adminNote ? filters.adminNote[0] : null,
        createdDateDESC: search,
        date: filters.createdDate ? filters.createdDate[0] : null
      };
      this.fetch({
        size: pagination.pageSize,
        page: pagination.current - 1,
        ..._params
      });
    },
    handleConfirm(value, key) {
      const _data = {
        orderId: value.id,
        statusId: key,
        adminNote: value.adminNote,
        shipPayment: value.shipPayment
      };
      this.updateOrder(_data);
    },
    updateShipper(value) {
      const _data = {
        shipPayment: value
      };
      this.updateOrder(_data);
    },
    setDataEdit(data, title) {
      this.modalEdit = {
        orderId: data.id,
        statusId: data.orderStatus.id,
        adminNote: data.adminNote,
        shipPayment: data.shipPayment,
        visible: true,
        title: title
      };
    },
    onchangeEdit(data, isUpdate) {
      if (isUpdate) {
        this.modalEdit = { ...data };
        this.updateOrder(data);
      }
      this.modalEdit = { ...this.modalEdit, visible: false };
    },
    async updateOrder(params) {
      try {
        const { data } = await orderRepository.updateStatus(params);
        if (!data.meta.success) throw new Error(data.meta.externalMessage);
        this.openNotification("Cập nhật đơn hàng thành công!");
        this.fetch({ page: this.pagination.current - 1 });
      } catch (e) {
        this.openNotification("Cập nhật đơn hàng xảy ra lỗi!");
      }
    },

    handleSearch(selectedKeys, confirm) {
      confirm();
      this.searchText = selectedKeys[0];
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },

    async fetch(params) {
      const _params = {
        page: 0,
        size: 10,
        createdDateDESC:1,
        ...params
      };
      const { data } = await orderRepository.getAllOrder(_params);
      if (!data.meta.success)
        throw new Error("Không thể lấy thông tin sản phẩm!");
      if (data.data) {
        this.loading = false;
        const pagination = { ...this.pagination };
        pagination.total = data.data.totalElements;
        this.loading = false;
        this.data = data.data.content;
        this.pagination = pagination;
      }
    },
    togleVisible(value) {
      if (value) {
        this.fetch();
      }
      this.visible = !this.visible;
    },
    async setVisibleDetail(order) {
      if (order) {
        try {
          let { data } = await orderRepository.getDetailOrder(order.id);
          if (!data.meta.success) throw new Error(data.meta.externalMessage);

          let customerInfoResponse = await orderRepository.getCustomerInfo({phone: order.customerPhone});
          let customerInfo = customerInfoResponse.data;
          this.dataDetail = { ...data.data, id: order.id, customerFullName: customerInfo.fullName };
        } catch (e) {
          this.openNotification("Có lỗi khi hiển thị thông tin!");
        }
      } else {
        this.dataDetail = {};
      }
    },

    openNotification(message) {
      this.$notification.open({
        message: message
      });
    },
    async getListStatus() {
      try {
        const { data } = await orderRepository.getListStatus();
        if (!data.meta.success) throw new Error(data.meta.externalMessage);
        this.listStatus = data.data;
      } catch (e) {
        this.openNotification("Có lỗi khi lấy danh sách đơn hàng!");
      }
    },
    formatCurrency: formatCurrency,
    formatPhone: formatPhone
  },
  created() {
    this.getListStatus();
  },
  watch: {}
};
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  padding: 15px 0;
}
.Dashboard {
  .admin-note {
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
  }
  .status1 {
    color: rgb(255, 132, 16);
  }
  .status2 {
    color: blue;
  }
  .status3 {
    color: rgb(8, 194, 85);
  }
  .status4 {
    color: red;
  }
}
</style>

export  function formatCurrency(num) {
    if(num)
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + 'đ'
    return num
}
  
export function formatPhone(num) {
    if(num.length === 10)
        return num.slice(0, 4) + '.' + num.slice(4, 7) + '.' + num.slice(7)
    return num;
}



